/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  color: rgb(29, 29, 29);
  background-color: white;
}

.center-content {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0px 24px;
}

h1,
h2,
h3,
h4,
h5 {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: block;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

strong {
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.select-alert {
  .alert-button-group {
    .alert-button {
      width: calc(50% - 6px);
      height: 44px;
      margin: 0px;
      border-radius: 40px;

      &:first-child {
        margin-right: 6px;
        color: rgb(146, 146, 146);
        background-color: rgb(255, 255, 255);
      }

      &:last-child {
        margin-left: 6px;
        color: rgb(46, 46, 46);
        background-color: rgb(241, 241, 241);
      }

      .alert-button-inner {
        text-transform: none;
        text-align: center;
        justify-content: center;
        font-weight: bold;
      }
    }
  }
}

.alert-custom,
.select-alert {
  .alert-head {
    padding-top: 34px;

    h2 {
      font-weight: bold;
      color: rgb(38, 38, 38);
    }
  }

  .alert-message {
    font-size: 15px;
    padding-bottom: 0px;
    height: auto !important;
    max-height: max-content !important;
    min-height: min-content !important;
    overflow: visible;
  }

  .alert-wrapper {
    --min-width: 316px;
    border-radius: 30px;
  }

  .alert-radio-group {
    max-height: 440px;
    border-top: none;
    border-bottom: none;
  }

  .alert-button-group {
    padding-top: 18px;
    padding-bottom: 26px;
    -webkit-padding-start: 24px;
    padding-inline-start: 24px;
    -webkit-padding-end: 24px;
    padding-inline-end: 24px;

    .alert-button {
      min-width: 80px;
      height: 44px;
      border-radius: 40px;
      font-size: 16px;

      .alert-button-inner {
        text-transform: none;
        text-align: center;
        justify-content: center;
        font-weight: bold;
      }

      &.half {
        width: calc(50% - 6px);
        margin: 0px;

        &:first-child {
          margin-right: 6px;
        }

        &:last-child {
          margin-left: 6px;
        }
      }

      &.full {
        width: 100%;
        margin: 4px 0px;
      }
    }
  }
}

.input-default {
  width: 100%;
  overflow: visible;
  margin-bottom: 18px;
  --padding-start: 8px;
  --padding-end: 8px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --border-radius: 6px;
  --highlight-background: rgb(154, 154, 154);

  ion-label {
    --color: rgb(26, 26, 26) !important;
  }

  &.item-has-focus {
    ion-label {
      color: rgb(26, 26, 26) !important;
    }
  }

  ion-select {
    margin-top: 8px;
  }

  ion-checkbox {
    margin-right: 18px;
  }

  ion-radio {
    margin-right: 18px;
  }

  &.break-text {
    ion-label {
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
    }
  }

  &.bg-transparent {
    --background: transparent;
  }

  &.input-toggle {
    --border-color: transparent;
    margin-bottom: 2px;
  }

  &.input-range {
    --border-color: transparent;
    margin-bottom: 2px;
  }

  &.input-checkbox {
    --border-color: transparent;
    margin-bottom: 2px;

    &.content-top {
      align-items: flex-start;

      ion-checkbox {
        margin-top: 14px;
      }
    }

    ion-label {
      white-space: normal;
      overflow: auto;
    }
  }

  &.input-radio {
    --border-color: transparent;
    margin-bottom: 2px;

    ion-label {
      white-space: normal;
      overflow: auto;
    }
  }
}

.button-default {
  width: max-content;
  padding: 16px 40px;
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
    transition: all 0.3s ease;
  }

  &.grey {
    background-color: #e7e7e7;
    color: #444444;
  }
}

// Header padrão
// ----------------------------------------------------
ion-header {
  padding: 16px 0px;

  &.no-shadow {
    &:after {
      display: none;
    }

    ion-toolbar {
      --border-width: 0px;
    }
  }

  .ios {
    ion-back-button {
      width: 40px;
      height: 40px;
      font-size: 14px;
      --border-radius: 50%;
      --padding-start: 0px;
      --icon-padding-start: 0px;
      --icon-margin-start: 0px;
      --padding-end: 0px;
      --icon-padding-end: 0px;
      --icon-margin-end: 0px;
    }
  }

  .md {
    ion-back-button {
      width: 40px;
      height: 40px;
      min-width: 40px;
      --icon-margin-start: 0px;
      --icon-margin-end: 0px;
      --icon-padding-start: 0px;
      --icon-padding-end: 0px;
      --padding-start: 10px;
      --padding-end: 10px;
      --border-radius: 50%;
    }
  }

  ion-toolbar {
    ion-buttons {
      &.buttons-first-slot {
        padding-left: 18px;
      }
      &.buttons-last-slot {
        padding-right: 18px;
      }

      ion-button {
        &.small {
          width: 40px !important;
          height: 40px !important;
          --padding-start: 0px !important;
          --padding-end: 0px !important;
          --border-radius: 50% !important;
        }

        &.button-default {
          height: 40px !important;
          --padding-start: 16px !important;
          --padding-end: 16px !important;
          --border-radius: 40px !important;

          ion-icon {
            margin-right: 8px;
          }
        }
      }
    }
  }

  &.header-white {
    ion-button {
      ion-icon {
        color: #444444;
      }
    }

    ion-back-button {
      --color: #444444;
      --background: #e7e7e7;
    }

    ion-toolbar {
      --background: #ffffff;
      --border-color: transparent;

      ion-title {
        font-size: 22px;
        font-weight: bold;
        color: #444444;
      }
    }
  }
}

@media (max-width: 800px) {
  ion-header {
    padding: 0px 0px;

    ion-toolbar {
      ion-title {
        font-size: 18px !important;
      }
    }
  }
}

ion-footer {
  &.no-shadow {
    &:before {
      display: none;
    }
  }
}

ion-modal {
  --border-radius: 26px;

  &.modal-transparent {
    --background: transparent;
    --backdrop-opacity: 0.7;

    .modal-wrapper {
      box-shadow: none !important;
    }
  }

  &.modal-medium {
    --width: 700px;
    --height: 90%;
  }

  &.modal-big {
    --width: 900px;
    --height: 90%;
  }

  &.modal-full {
    --width: 90%;
    --height: 90%;
  }
}

@media (max-width: 800px) {
  ion-modal {
    --width: 100% !important;
    --height: 100% !important;
    --border-radius: 0px !important;
  }
}

// CARD

.card-default {
  width: 100%;
  margin: 0 0 16px 0;
  box-shadow: none;
  overflow: initial;

  &.radius {
    border-radius: 26px;
  }

  &.shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  &.border-grey {
    border: 1px solid var(--ion-color-bluegrey-100);
  }

  &.border-primary {
    border: 1px solid var(--ion-color-primary);
  }

  &.border-primary-bold {
    border: 2px solid var(--ion-color-primary);
  }

  &.small-padding {
    ion-card-content {
      padding: 14px;
    }
  }

  ion-card-content {
    padding: 32px;
    &.small-padding {
      padding: 14px;
    }

  }

  @media (max-width: 950px) {
    ion-card-content {
      padding: 20px;
    }
  }
}

.bg-wrapper {
  background: #f5f5f5;
  border-radius: 26px;
  padding: 20px;
  width: 100%;
  margin: 0px 0px 16px 0px;

  ion-list {
    background: transparent;
  }

  ion-item {
    --background: transparent;
  }
}

// IonCalendar
// --------------------------------------------------------------------

.card-calendar {
  &.grey {
    background: var(--ion-color-bluegrey-50);
  }

  .header-calendar {
    width: 100%;
    padding: 0 0 12px 0;
    display: flex;
    flex-direction: row;
  }

  .col-info {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .date {
      width: 100%;
      font-size: 14px;
      display: block;
      font-family: helveticaBold;
      color: var(--ion-color-bluegrey-800);
    }
  }

  .col-buttons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ion-button {
      margin: 0px 0px 0px 6px;
    }
  }
}

.legenda-calendario {
  padding: 12px 22px;
  justify-content: flex-start;

  .legenda {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: 0 44px 12px 0;
    padding: 0 0 0 32px;
    font-size: 16px;
    color: var(--ion-color-bluegrey-800);
    //font-family: helveticaBold;

    &:after {
      content: '';
      display: block;
      top: 50%;
      left: 0;
      position: absolute;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #40BAFF;
    }

    &.esgotados:after {
      background: #bfc8cb;
    }

    &.danger:after {
      background: #E74C3C;
    }

    &.warning:after {
      background: #FFC101;
    }
  }
}

.with-event {
  top: 0;
  left: 0;
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  //background: #eff1f5;
  border-radius: 8px;

  &.esgotado {
    //background: #bfc8cb;
    //background: rgba(191, 200, 203, .5);
  }

  &.disponivel {
    //background: #40BAFF;
    //background: rgba(64, 186, 255, .5);
  }

  .indicator-container {
    left: 50%;
    bottom: -4px;
    margin-left: -1px;
    position: absolute;
    transform: rotate(90deg);

    .event-indicator.disponivel::after {
      background: rgba(64, 186, 255, 1);
      margin-top: 2px;
    }

    .event-indicator.ausencia-parcial::before {
      background: #FFC101;
    }

    .event-indicator.ausencia-total::before {
      background: #E74C3C;
    }

    .event-indicator::after,
    .event-indicator::before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      color: #FFFFFF;
      background: transparent;
    }
  }
}

calendar {
  .monthview-container {
    max-width: 350px;
    margin: 0 auto;

    .table-bordered {
      border: none !important;
    }

    table {
      thead {
        tr {
          th {
            border: none !important;

            small {
              font-size: 12px;
              font-family: helveticaBold;
              color: var(--ion-color-bluegrey-900);
              text-transform: capitalize;
            }
          }
        }
      }

      tbody {
        padding-top: 16px;

        tr {
          td {
            width: 50px;
            height: 38px;
            position: relative;
            padding: 12px 8px;
            color: var(--ion-color-bluegrey-700);
            border: 2px solid #eff1f5 !important;

            &.monthview-selected {
              border-radius: 8px;
              font-family: helveticaMedium;
              background: rgba(var(--ion-color-primary-rgb), .8) !important;
              color: var(--ion-color-white) !important;
            }

            &.monthview-primary-with-event {
              border-radius: 8px;
              background: #eff1f5;
              font-family: helveticaMedium;
              color: var(--ion-color-bluegrey-700);

              &.monthview-selected.monthview-current {
                color: var(--ion-color-white) !important;
              }
            }
          }
        }
      }
    }
  }
}


// --- angular-calendar
.cal-week-view{
  border: none !important;
  .cal-header{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: none;
    }
  }
}
.cal-week-view .cal-time-events {
  //border: none !important;
  border-right: 0.8px solid #DEE9EE !important;
  border-left: 0.8px solid #DEE9EE !important;
}
.cal-week-view .cal-day-headers {
  border: none !important;
}
.cal-header{
  border: none !important;
}
.cal-hour{
  background-color: #F6F8FB !important;
  background-color: #FFF;
}

.cal-hour-segment {
  border-bottom: 0.8px solid #DEE9EE !important;
  background-color: #FFF;
}
.cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: block !important;
}
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: block !important;
}
.cal-day-view mwl-calendar-week-view-header{
  display: block !important;
}
.cal-cell-row {
  height: 100%;
}
.cal-after-hour-star {
  background-color: gray;
}

.cal-disabled {
  background-color: #F0F4F5;
  pointer-events: none;
}
.cal-day-headers .cal-header:first-child {
  border: none !important;
  background-color: transparent !important;
}
:host ::ng-deep .cal-event-container {
  height: 100px;
}
.fc-slats {
  height: 60px;
}
.cal-time-events{
  border-right: 0.8px solid #DEE9EE !important;
}
.cal-time-label-column{
  background-color: #FFF;
  .cal-hour-segment {
    padding-top: 5px;
    //border-color: #FFF !important;
    background-color: #FFF;
    //border-bottom: inherit !important;
  }
  .cal-disabled {
    background-color: #FFF;
    border-color: #FFF !important;
    pointer-events: none;
  }
}
.cal-day-view .cal-time {
  font-weight: bold;
  padding-top: 5px;
  width: 70px;
  text-align: center;
  //position: absolute;
  //margin-left: -66px;
}
mwl-calendar-day-view{
  margin-left: 66px;
  .cal-day-view .cal-current-time-marker {
    margin-left: 0px;
    width: 100%;
  }
  .cal-day-view .cal-events-container {
    margin-left: 0px;
  }
  .cal-header{

    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: none;
    }
  }
}
mwl-calendar-month-view {
  .cal-today {
    background-color: #DEE9EE !important;
    //border-top: inherit !important;
  }
  .cal-month-view .cal-days {
    border: 1px solid #DEE9EE !important;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
mwl-calendar-day-view .cal-day-headers{
  margin-left: 0px !important;
}
.day-view{
  //margin-left: 66px;
}
.cal-day-headers  {
  background: #DEE9EE;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.cal-today{
  //background-color: #FFF !important;

  background-color: #EAF6F8 !important;
  border-top: solid 2px #EAF6F8 !important;
}
.cal-week-view .cal-day-headers {
  padding-left: 86px;
}
.cal-week-view .cal-event {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
  border: none;
}
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  padding: 0 0px;
  line-height: 0px;
}
.cal-week-view .cal-time-label-column {
  width: 86px;
  height: 100%;
}
.cal-week-view .cal-time {
  font-weight: bold;
  padding-top: 5px;
  padding-left: 14px;
  width: 70px;
  text-align: center;
}

.div-com-bolinhas {
  position: relative;
}

.div-com-bolinhas::before,
.div-com-bolinhas::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
}

.ausencia-parcial::after {
  background-color: #FFC101; /* Cor da primeira bolinha */
  right: 8px;
}

.ausencia-total::after {
  background-color: #ff0000; /* Cor da primeira bolinha */
  right: 8px;
}

.disponivel::before {
  background-color: #40baff; /* Cor da segunda bolinha */
  left: 8px;
}


ion-calendar {
  padding: inherit !important;
  box-sizing: border-box;
  display: inline-block;
  background-color: inherit !important;
  width: 100%;
  .days{
    margin-bottom: 11px !important;

    button {
      p {
        color: #333 !important;
      }
    }
  }
  .title {
    font-weight: 600;
    text-transform: capitalize;
  }
}
